import productionApi from '@src/api/productionApi';
import SubContentTabs from '@src/components/General/SubContentTabs';
import Text from '@src/components/General/Text';
import Flex from '@src/components/Views/Flex';
import FlexRow from '@src/components/Views/FlexRow';
import { productionType } from '@src/interfaces/ProductionInterface';
import { useEffect, useState } from 'react';
import { ScrollView, TouchableOpacity } from 'react-native-gesture-handler';
import { ActivityIndicator, Dimensions } from 'react-native';
import { Time, Timecard } from '../../WorkSchedule';
import { Payments } from '../WeekViewTab/Payments/Payments';
import { Timecards } from '../WeekViewTab/Timecards/Timecards';
import { Review } from '../WeekViewTab/Review/Review';
import ArrowLeft from '@assets/icons/arrow-left.svg';
import ArrowRight from '@assets/icons/arrow-right.svg';
import { useNavigation } from '@react-navigation/native';
import { navigationProps } from '@src/interfaces/RoutesInterface';
import moment from 'moment';

const { width } = Dimensions.get('window');

type WeeklyViewProps = {
  timecard: Timecard;
  timecards: Timecard[];
  title: string;
  production: productionType;
  subtab?: string;
  onChange: (timecard: Timecard) => void;
  onDayChose: (day: Time) => void;
  onApproveReview: () => void;
};

const TABS = {
  TIMERCARDS: 'timecards',
  PAYMENTS: 'other payments',
  REVIEW: 'review',
};

export const WeeklyView = ({
  timecard,
  timecards,
  title,
  production,
  onChange,
  onDayChose,
  subtab,
  onApproveReview,
}: WeeklyViewProps) => {
  const navigation = useNavigation<navigationProps>();

  const defaultTab =
    subtab && Object.values(TABS).includes(subtab) ? subtab : TABS.TIMERCARDS;

  const [tab, setTab] = useState(defaultTab);

  const [loading, setLoading] = useState<boolean>(true);
  const [details, setDetails] = useState<Timecard | null>(null);

  useEffect(() => {
    getTimecard(timecard.id);
  }, [timecard.id]);

  const getTimecard = async (timecardId: string) => {
    setLoading(true);

    let response = await productionApi.getTimecard(timecardId);
    setDetails(response.data);

    setLoading(false);
  };

  const isPreviousDisabled =
    timecards.length > 0 && timecards[0].id === timecard.id;

  const isNextDisabled =
    timecards.length > 0 && timecards[timecards.length - 1].id === timecard.id;

  const status = {
    approved: (
      <Text type="xs-semibold" color="emerald-100">
        Wrapped
      </Text>
    ),
    progress: (
      <Text type="xs-semibold" color="navy-light-100">
        In Progress
      </Text>
    ),
    action: (
      <Text type="xs-semibold" color="coral-100">
        Action Required
      </Text>
    ),
    scheduled: (
      <Text type="xs-semibold" color="denim-50">
        Scheduled
      </Text>
    ),
  };

  const items = details?.attributes.times || [];

  const hasInvalidItem = () =>
    items.some((item) => {
      const meal1Required = item.required_meals_count >= 1;
      const meal2Required = item.required_meals_count === 2;

      if (moment(item.date).isBefore(moment())) {
        return true;
      }

      if (!item.checkin_time || !item.checkout_time) {
        return true;
      }

      if (meal1Required && !item.meal1) {
        return true;
      }

      if (meal2Required && !item.meal2) {
        return true;
      }

      return false;
    });

  const getCurrentStatus = (timecard: Timecard) => {
    if (timecard.attributes.is_approved) {
      return status['approved'];
    }

    if (timecard.attributes.status === 'scheduled') {
      return status['scheduled'];
    }

    if (hasInvalidItem()) {
      return status['action'];
    }

    if (timecard.attributes.status === 'active') {
      return status['progress'];
    }

    return status['scheduled'];
  };

  const getTimecardsNotificationQuantity = () =>
    loading
      ? 0
      : items.filter((item) => {
          const meal1Required = item.required_meals_count >= 1;
          const meal2Required = item.required_meals_count === 2;

          if (moment(item.date).isAfter(moment())) {
            return false;
          }

          if (!item.checkin_time || !item.checkout_time) {
            return true;
          }

          if (meal1Required && !item.meal1) {
            return true;
          }

          if (meal2Required && !item.meal2) {
            return true;
          }

          return false;
        }).length;

  return (
    <ScrollView>
      <FlexRow className="justify-between items-center py-2.5 px-6">
        <TouchableOpacity
          disabled={isPreviousDisabled}
          className={`text-gray-400 bg-gray-100 px-5 py-2.5 rounded-2xl ${
            isPreviousDisabled ? 'opacity-50' : 'opacity-100'
          }`}
          onPress={() => {
            const index = timecards.findIndex(
              (card) => card.id === timecard.id
            );

            onChange(timecards[index - 1]);
          }}
        >
          <ArrowLeft width={14} height={14} fill="#131E3C80" />
        </TouchableOpacity>
        <Flex className="justify-center items-center">
          <Text type="2xs-semibold" color="denim-50">
            {title}
          </Text>
          <Text type="base-semibold" color="black" className="text-center">
            {moment(timecard.attributes.start_date).format('MMM DD')} -{' '}
            {moment(timecard.attributes.end_date).format('MMM DD, YYYY')}
          </Text>
          {getCurrentStatus(timecard)}
        </Flex>

        <TouchableOpacity
          disabled={isNextDisabled}
          className={`text-gray-400 bg-gray-100 px-5 py-2.5 rounded-2xl ${
            isNextDisabled ? 'opacity-50' : 'opacity-100'
          }`}
          onPress={() => {
            const index = timecards.findIndex(
              (card) => card.id === timecard.id
            );

            onChange(timecards[index + 1]);
          }}
        >
          <ArrowRight width={14} height={14} fill="#131E3C80" />
        </TouchableOpacity>
      </FlexRow>
      <SubContentTabs
        values={[
          'Timecards',
          width > 400 ? 'Other Payments ' : 'Payments',
          'Review',
        ]}
        align="start"
        defaultSelected={tab}
        ribbles={[
          getTimecardsNotificationQuantity(),
          0,
          timecard.attributes.approved_at == null &&
          timecard.attributes.incomplete_days_count == 0
            ? 1
            : 0,
        ]}
        onSelected={(value) => {
          setTab(value);

          navigation.navigate('Production', {
            tab: 'work-schedule',
            subtab: value,
            productionId: production.id,
            timecardId: timecard.id,
          });
        }}
      />
      {(loading || !details) && (
        <Flex className="flex-1">
          <ActivityIndicator className="mt-5" />
        </Flex>
      )}

      {tab === TABS.TIMERCARDS && details && !loading && (
        <Timecards
          timecard={{ ...details?.attributes, id: timecard.id }}
          onReview={() => {
            setTab(TABS.REVIEW);

            navigation.navigate('Production', {
              tab: 'work-schedule',
              subtab: TABS.REVIEW,
              productionId: production.id,
              timecardId: timecard.id,
            });
          }}
          onDayChose={onDayChose}
        />
      )}

      {tab.includes('payments') && details && !loading && (
        <Payments timecard={details} production={production} />
      )}

      {tab === TABS.REVIEW && details && !loading && (
        <Review
          timecard={details}
          production={production}
          onApprove={onApproveReview}
        />
      )}
    </ScrollView>
  );
};
