import productionApi from '@src/api/productionApi';
import Button from '@src/components/Buttons/Button';
import Text from '@src/components/General/Text';
import { YourProductionContact } from '@src/components/General/YourProductionContact';
import Flex from '@src/components/Views/Flex';
import FlexRow from '@src/components/Views/FlexRow';
import FloatingBottom from '@src/components/Views/FloatingBottom';
import {
  isEmpty,
  transformTailwindToHex,
  translatedAmount,
} from '@src/helpers';
import { productionType, ShootType } from '@src/interfaces/ProductionInterface';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { ScrollView } from 'react-native-gesture-handler';
import { Time, Timecard } from '../../../WorkSchedule';

type ReviewProps = {
  timecard: Timecard;
  production: productionType;
  onApprove?: () => void;
};

const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M6.57966 0L12 6L6.57966 12L5.54577 10.8555L9.20114 6.80926H0V5.19074H9.20114L5.54577 1.14446L6.57966 0Z"
      fill="#131E3C"
      fill-opacity="0.5"
    />
  </svg>
);

type CircleIconProps = {
  stroke?: string;
};

const CircleIcon = ({ stroke = '#131E3C' }: CircleIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <circle
      cx="8.5"
      cy="8"
      r="7.25"
      stroke={stroke}
      stroke-opacity="0.3"
      stroke-width="1.5"
    />
  </svg>
);

type CheckIconProps = {
  color?: string;
};

const CheckIcon = ({ color = '#0062EC' }: CheckIconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <path
      d="M16.5 8C16.5 12.4183 12.9183 16 8.5 16C4.08172 16 0.5 12.4183 0.5 8C0.5 3.58172 4.08172 0 8.5 0C12.9183 0 16.5 3.58172 16.5 8Z"
      fill={color}
    />
    <path
      d="M13.2376 4.81802C12.9252 4.5056 12.4187 4.5056 12.1063 4.81802L7.43934 9.48492L4.89376 6.93934C4.58134 6.62692 4.07481 6.62692 3.76239 6.93934C3.44997 7.25176 3.44997 7.75829 3.76239 8.07071L6.87366 11.182C7.18608 11.4944 7.69262 11.4944 8.00503 11.182L13.2376 5.94939C13.55 5.63697 13.55 5.13044 13.2376 4.81802Z"
      fill="white"
    />
  </svg>
);

const CircleXIcon = ({ color }: { color: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <path
      d="M11.6819 10.0507C11.9944 10.3631 11.9944 10.8696 11.6819 11.182C11.3695 11.4944 10.863 11.4944 10.5506 11.182L8.50006 9.13151L6.44944 11.1821C6.13703 11.4945 5.63049 11.4945 5.31807 11.1821C5.00565 10.8697 5.00565 10.3632 5.31807 10.0507L7.36868 8.00014L5.31798 5.94943C5.00556 5.63702 5.00556 5.13048 5.31798 4.81806C5.6304 4.50564 6.13693 4.50564 6.44935 4.81806L8.50006 6.86877L10.5507 4.81816C10.8631 4.50574 11.3696 4.50574 11.682 4.81816C11.9945 5.13058 11.9945 5.63711 11.682 5.94953L9.63142 8.00014L11.6819 10.0507Z"
      fill={color}
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.5 8C16.5 12.4183 12.9183 16 8.5 16C4.08172 16 0.5 12.4183 0.5 8C0.5 3.58172 4.08172 0 8.5 0C12.9183 0 16.5 3.58172 16.5 8ZM14.9 8C14.9 11.5346 12.0346 14.4 8.5 14.4C4.96538 14.4 2.1 11.5346 2.1 8C2.1 4.46538 4.96538 1.6 8.5 1.6C12.0346 1.6 14.9 4.46538 14.9 8Z"
      fill={color}
    />
  </svg>
);

const CircleDashedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <path
      d="M13 8C13 7.55817 12.6418 7.2 12.2 7.2H4.8C4.35817 7.2 4 7.55817 4 8C4 8.44183 4.35817 8.8 4.8 8.8H12.2C12.6418 8.8 13 8.44183 13 8Z"
      fill="#131E3C"
      fill-opacity="0.3"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.5 8C16.5 12.4183 12.9183 16 8.5 16C4.08172 16 0.5 12.4183 0.5 8C0.5 3.58172 4.08172 0 8.5 0C12.9183 0 16.5 3.58172 16.5 8ZM14.9 8C14.9 11.5346 12.0346 14.4 8.5 14.4C4.96538 14.4 2.1 11.5346 2.1 8C2.1 4.46538 4.96538 1.6 8.5 1.6C12.0346 1.6 14.9 4.46538 14.9 8Z"
      fill="#131E3C"
      fill-opacity="0.3"
    />
  </svg>
);

export const Review = ({
  production,
  timecard: defaultTimecard,
  onApprove,
}: ReviewProps) => {
  const [timecard, setTimecard] = useState(defaultTimecard);
  const [loading, setLoading] = useState(false);
  const payments =
    timecard.related.bonuses.data.filter(
      (bonus) => bonus.attributes.status === 'approved'
    ) || [];

  const times = timecard.attributes.times || [];
  const [details, setDetails] = useState<ShootType[]>([]);

  useEffect(() => {
    getShootDays();
  }, []);

  const getShootDays = async () => {
    setLoading(true);

    const ids = times.map((time) => time.id);

    const promises = ids.map((id) => productionApi.getShoot(id));

    const response: { data: ShootType }[] = await Promise.all(promises);

    setDetails(response.map((response) => response.data));

    setLoading(false);
  };

  const isUpdatedByAdmin = details.some(
    (shootday) =>
      (shootday.attributes.checkin_by &&
        shootday.attributes.checkin_by != 'Self') ||
      (shootday.attributes.checkout_by &&
        shootday.attributes.checkout_by != 'Self') ||
      (shootday.attributes.meals_by && shootday.attributes.meals_by != 'Self')
  );

  const approveTimecard = async () => {
    setLoading(true);

    await productionApi.approveTimecard(timecard.id);

    onApprove?.();

    const response = await productionApi.getTimecard(timecard.id);

    setTimecard(response.data);

    getShootDays();

    setLoading(false);
  };

  return (
    <ScrollView>
      {timecard.attributes.is_approved && (
        <Flex className="bg-[#131E3C1A] py-4 border border-[#131E3C33]">
          <Text type="xs-medium" color="denim-50" className="px-6">
            This week has been wrapped - all daily timecards & other payments
            are locked.
          </Text>
        </Flex>
      )}

      {moment().isSameOrAfter(moment(timecard.attributes.start_date)) &&
        timecard.attributes.approved_at == null &&
        timecard.attributes.incomplete_days_count == 0 && (
          <Flex className="bg-[#F9CA254D] py-4 border border-[#131E3C33]">
            <Text type="xs-medium" color="denim-50" className="px-6">
              Your daily timecards and other payment items from this week. For
              timely payroll, please approve ASAP.
            </Text>
          </Flex>
        )}

      {!moment().isSameOrAfter(moment(timecard.attributes.start_date)) && (
        <Flex className="bg-[#131E3C1A] py-4 border border-[#131E3C33]">
          <Text type="xs-medium" color="denim-50" className="px-6">
            This week is not active yet - timecards & payments are locked until
            the scheduled start date.
          </Text>
        </Flex>
      )}
      <Text type="xs-semibold" color="navy-100" className="px-6 mb-5 mt-10">
        Days Worked
      </Text>

      <FlexRow className="justify-between bg-slate-800/5 py-4 px-6">
        <Text type="xs-medium" color="denim-50" className="w-28">
          Work Date
        </Text>
        <Text type="xs-medium" color="denim-50" className="w-14">
          Time In
        </Text>
        <Text
          type="xs-medium"
          color="denim-50"
          className=" w-6 justify-center flex"
        >
          <ArrowIcon />
        </Text>
        <Text type="xs-medium" color="denim-50" className="bg-blu-300 w-14">
          Out
        </Text>

        <Text
          type="xs-medium"
          color="denim-50"
          className=" w-16 justify-center flex"
        >
          1st Meal
        </Text>
        <Text
          type="xs-medium"
          color="denim-50"
          className=" w-8 justify-center flex"
        >
          2nd
        </Text>
      </FlexRow>
      <Flex className="border-b-0.5 border-denim-20 px-6">
        {times.map((time) => {
          const detail = details.find((detail) => detail.id === time.id);

          const isMealCompleted = (meal: any, mealNumber: number) => {
            if (time == null) return false;

            if (time.required_meals_count == 0 || meal?.penalty) {
              return true;
            }

            if (time.required_meals_count == 1 && mealNumber == 1) {
              return !isEmpty(meal?.start_time);
            }

            if (time.required_meals_count == 2 && mealNumber == 2) {
              return !isEmpty(meal?.start_time);
            }

            return true;
          };

          const getMealIcon = (meal: any, order: number) => {
            if (meal?.penalty && detail?.attributes.meals_by === 'Self') {
              return (
                <CircleXIcon color={transformTailwindToHex('navy-light-100')} />
              );
            }

            if (meal?.penalty && detail?.attributes.meals_by !== 'Self') {
              return (
                <CircleXIcon color={transformTailwindToHex('grape-100')} />
              );
            }

            if (!time.required_meals_count && !meal) {
              return <CircleDashedIcon />;
            }

            if (isMealCompleted(meal, order)) {
              return (
                <CheckIcon
                  color={
                    timecard.attributes.is_approved
                      ? transformTailwindToHex('emerald-100')
                      : detail?.attributes.meals_by
                      ? transformTailwindToHex('grape-100')
                      : transformTailwindToHex('navy-light-100')
                  }
                />
              );
            }

            return <CircleIcon />;
          };

          const getCheckinTextColor = () => {
            if (timecard.attributes.is_approved) {
              return 'emerald-100';
            }

            if (
              detail?.attributes.is_locked &&
              detail?.attributes.checkin_by != null &&
              detail?.attributes.checkin_by == 'Self'
            ) {
              return 'navy-light-100';
            }

            if (
              detail?.attributes.checkin_by != null &&
              detail?.attributes.checkin_by != 'Self'
            ) {
              return 'grape-100';
            }

            if (
              !detail?.attributes.is_locked &&
              detail?.attributes.checkin_by != null &&
              detail?.attributes.checkin_by == 'Self'
            ) {
              return 'navy-light-100';
            }

            return 'denim-50';
          };

          const getCheckoutTextColor = () => {
            if (timecard.attributes.is_approved) {
              return 'emerald-100';
            }

            if (
              detail?.attributes.is_locked &&
              detail?.attributes.checkout_by != null &&
              detail?.attributes.checkout_by == 'Self'
            ) {
              return 'navy-light-100';
            }

            if (
              detail?.attributes.checkout_by != null &&
              detail?.attributes.checkout_by != 'Self'
            ) {
              return 'grape-100';
            }

            if (
              !detail?.attributes.is_locked &&
              detail?.attributes.checkout_by != null &&
              detail?.attributes.checkout_by == 'Self'
            ) {
              return 'navy-light-100';
            }

            return 'denim-50';
          };

          return (
            <FlexRow className="justify-between h-12 py-4 w-full">
              <FlexRow align="between" className="w-28 pr-14">
                <Text type="xs-medium" color="denim-50">
                  {moment(time.date).format('ddd').slice(0, -2)}
                </Text>
                <Text type="xs-medium" color="denim-50">
                  {moment(time.date).format('MM/DD')}
                </Text>
              </FlexRow>
              <Text
                type="xs-medium"
                color={getCheckinTextColor()}
                className="w-14 text-left pl-0.5"
              >
                {!loading
                  ? time.checkin_time
                    ? moment(time.checkin_time)
                        .parseZone()
                        .format('h:mma')
                        .replace('m', '')
                    : '-'
                  : '-'}
              </Text>
              <Text
                type="xs-medium"
                color="denim-50"
                className="w-6 flex justify-center"
              >
                <ArrowIcon />
              </Text>
              <Text
                type="xs-medium"
                color={getCheckoutTextColor()}
                className="pl-0.5 w-14"
              >
                {!loading
                  ? time.checkout_time
                    ? moment(time.checkout_time)
                        .parseZone()
                        .format('h:mma')
                        .replace('m', '')
                    : '-'
                  : '-'}
              </Text>

              <Text
                type="xs-medium"
                color="denim-50"
                className=" w-16 justify-center flex"
              >
                {loading ? '-' : getMealIcon(time.meal1, 1)}
              </Text>
              <Text
                type="xs-medium"
                color="denim-50"
                className=" w-8 justify-center flex"
              >
                {loading ? '-' : getMealIcon(time.meal2, 2)}
              </Text>
            </FlexRow>
          );
        })}
      </Flex>
      {isUpdatedByAdmin && (
        <Text type="xs-medium" color="denim-50" className="px-6 mt-1.5">
          Note:{' '}
          <Text type="xs-medium" color="grape-100">
            Purple Items
          </Text>{' '}
          have been updated by an Admin.
        </Text>
      )}

      <Text type="xs-semibold" color="navy-100" className="px-6 mb-5 mt-10">
        Approved Other Payments
      </Text>

      <FlexRow className="justify-between bg-slate-800/5 py-4 px-6">
        <Text type="xs-medium" color="denim-50">
          Payment Type
        </Text>
        <Text type="xs-medium" color="denim-50">
          Amount
        </Text>
      </FlexRow>
      <Flex className="px-6">
        {payments.map((bonus) => (
          <FlexRow
            className="h-12 border-b-0.5 items-center border-denim-20"
            align="between"
          >
            <Text type="base-medium" color="denim-50">
              {bonus.attributes.type}
            </Text>
            <Text type="base-medium" color="navy-light-100">
              {translatedAmount(bonus.attributes.amount)}
            </Text>
          </FlexRow>
        ))}
      </Flex>
      {!payments.length && (
        <Flex align="center" className="border-b-0.5 px-6 border-denim-20">
          <Text
            type="xs-medium"
            color="denim-50"
            className="h-12 flex align-middle items-center"
          >
            No Other Payments Approved Yet
          </Text>
        </Flex>
      )}
      <YourProductionContact production={production} />

      {timecard.attributes.approved_at == null &&
        timecard.attributes.incomplete_days_count == 0 && (
          <FloatingBottom>
            <Button
              useFullColor={true}
              value="Approve All"
              color="navy-light-100"
              onPress={() => approveTimecard()}
            />
          </FloatingBottom>
        )}
    </ScrollView>
  );
};
