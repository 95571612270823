import React, { useEffect, useRef, useState } from 'react';

import Text from '@components/General/Text';
import Flex from '@components/Views/Flex';
import productionApi from '@api/productionApi';
import FlexRow from '@components/Views/FlexRow';
import Button from '@components/Buttons/Button';
import { StackScreenProps } from '@react-navigation/stack';
import { NonAuthStackParamList } from '@src/routes';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import RBSheet from 'react-native-raw-bottom-sheet';
import moment from 'moment';
import {
  formatDateDefault,
  msToHMS,
  transformTailwindToHex,
} from '@src/helpers';
import CheckRounded from '@assets/icons/check-rounded.svg';
import AsyncStorage from '@react-native-async-storage/async-storage';

import Header from '@components/Views/Header';
import SecondaryButton from '@components/Buttons/SecondaryButton';
import { getGlobal } from 'reactn';
import { DeviceType } from 'expo-device';
import FloatingBottom from '@components/Views/FloatingBottom';
import { Details } from '@src/pages/SignedIn/Production/components/OverviewTab/Details';
import { GuarantedHours } from '@src/pages/SignedIn/Production/components/OverviewTab/GuarantedHours';
import { ProductionDetails } from '@src/components/General/ProductionDetails';
import { Locations } from '@src/components/General/Locations';
import 'moment-countdown';
import { CrewPolicies } from '@src/components/General/CrewPolicies';
import {translatedGuaranteedHours} from "@components/General/Rates";

type OfferSummaryProps = StackScreenProps<
  NonAuthStackParamList,
  'OfferSummary'
>;
export default function OfferSummary({ navigation, route }: OfferSummaryProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [offer, setOffer] = useState<any>(null);
  const [status, setStatus] = useState<any>(null);
  const [timeleft, setTimeLeft] = useState<string>('00:00:00');
  const [declineType, setDeclineType] = useState<number>(0);
  const [acceptType, setAcceptType] = useState<number>(0);
  const [viewDetailsTitle, setViewDetailsTitle] = useState<string>('');
  const [viewDetailsContent, setViewDetailsContent] = useState<string>('');

  const refDeclineOffer = useRef<RBSheet>(null);
  const refEmploymentTypePopup = useRef<RBSheet>(null);
  const refViewDetailsPopup = useRef<RBSheet>(null);

  useEffect(() => {
    getOffer();
  }, [navigation, route]);

  useEffect(() => {
    updateTimeLeft();
  }, [offer]);

  const getOffer = async () => {
    setLoading(true);

    const response = await productionApi.getPublicOffer(route.params.id);

    if (
      response.data.has_login &&
      response.data.status != 'expired' &&
      navigation.getState().routeNames.includes('Login')
    ) {
      navigation.navigate('Login');
      return;
    }

    if (response.data.status == 'accepted') {
      navigation.navigate('HowItWorks');
    }

    setOffer(response.data);
    setStatus(response.data.status);
    setLoading(false);
  };

  const updateTimeLeft = async () => {
    if (offer != null) {
      if (offer.expiration == null) {
        setTimeLeft('-');
      } else {
        let expirationTime = moment(offer.expiration);

        if (moment().isAfter(expirationTime)) {
          setTimeLeft('00:00:00');
          return;
        }

        const time = expirationTime.countdown();
        setTimeLeft(msToHMS(time.value));

        setTimeout(() => updateTimeLeft(), 1000);
      }
    }
  };
  const rejectOffer = async () => {
    refDeclineOffer.current?.close();

    setLoading(true);
    let response = await productionApi.rejectPublicOffer(
      route.params.id,
      declineType == 1
    );
    getOffer();
  };

  const acceptOffer = async () => {
    let response = await productionApi.acceptPublicOffer(route.params.id, {
      loan_out: acceptType,
    });

    response.data.signup.loan_out = acceptType;

    await AsyncStorage.setItem(
      'signupData',
      JSON.stringify(response.data.signup)
    );
    await AsyncStorage.setItem('signupOfferId', route.params.id);
    await AsyncStorage.setItem('signupProductionId', offer.production_id);
    await AsyncStorage.setItem('signupProductionName', offer.production_name);
    await AsyncStorage.setItem('signupProductionEmployment', offer.employment);
    await AsyncStorage.setItem('signupProductionHasI9', offer.require_i9);
    await AsyncStorage.setItem(
      'signupProductionLoanOut',
      offer.employment == 'loan_out' || acceptType == 1 ? 'true' : 'false'
    );

    navigation.navigate('HowItWorks');
  };

  if (offer == null) {
    return null;
  }

  return (
    <Flex className="bg-white flex flex-1" verticalAlign="between">
      <Header title="Offer Details" showBack={false} />
      <ScrollView>
        <Flex className="gap-y-1 py-4 mx-6 ">
          <FlexRow verticalAlign="center" align="between">
            <Text type="sm-semibold" color="navy-100">
              {offer.role}
            </Text>
            {status != 'rejected' && status != 'expired' && timeleft != '-' && (
              <Text type="xs-semibold" color="coral-100">
                Expires in {timeleft}
              </Text>
            )}
            {status != 'rejected' && status != 'expired' && timeleft == '-' && (
              <Text type="xs-semibold" color="coral-100">
                Expires In: N/A
              </Text>
            )}
            {status == 'rejected' && (
              <Text type="xs-semibold" color="coral-100">
                Declined
              </Text>
            )}
            {status == 'expired' && (
              <Text type="xs-semibold" color="coral-100">
                Expired
              </Text>
            )}
          </FlexRow>

          <Text type="xs-medium" color="denim-50" className="mt-1.5">
            Offered By: {offer.company_name}
          </Text>
          <View className="border-b border-navy-light-100/10 pt-3 -mx-7 mb-3" />
        </Flex>
        <Details
          production={{
            attributes: offer,
            id: offer.production_id,
            related: offer.related,
          }}
        />
        <GuarantedHours hoursAmount={translatedGuaranteedHours(offer.rate)}/>
        <Flex className="mt-10">
          <ProductionDetails
            title={offer.production_name}
            type={offer.production_type}
            onOpenSynopsis={() => {
              setViewDetailsTitle('Production Synopsis');
              setViewDetailsContent(offer.production_synopsis);
              refViewDetailsPopup.current?.open();
            }}
          />
        </Flex>

        <Flex className="mt-10 mb-10">
          <Locations
            cities={offer.cities.map(
              (data: {
                last_shooting_day: string;
                first_shooting_day: string;
                city: string;
              }) => {
                return {
                  location: `${data.city} (${moment(
                    data.last_shooting_day
                  ).diff(moment(data.first_shooting_day), 'days')} Days)`,
                  date: `${formatDateDefault(
                    data.first_shooting_day
                  )} - ${formatDateDefault(data.last_shooting_day)}`,
                };
              }
            )}
          />
        </Flex>

        {(offer.policies.travel != undefined ||
          offer.policies.expense != undefined ||
          offer.policies.bonus != undefined) && (
          <Flex>
            <CrewPolicies
              policies={[
                {
                  title: 'Travel Compensation',
                  policy: offer.policies.trave,
                },
                {
                  title: 'Expense',
                  policy: offer.policies.expense,
                },
                {
                  title: 'Reimbursement',
                  policy: offer.policies.bonus,
                },
              ].filter((policy) => policy.policy)}
              onOpenPolicy={(policy) => {
                if (policy === 'Travel Compensation') {
                  setViewDetailsTitle('Travel Compensation');
                  setViewDetailsContent(offer.policies.travel);
                  refViewDetailsPopup.current?.open();
                }

                if (policy === 'Expense') {
                  setViewDetailsTitle('Expense');
                  setViewDetailsContent(offer.policies.expense);
                  refViewDetailsPopup.current?.open();
                }

                if (policy === 'Reimbursement') {
                  setViewDetailsTitle('Reimbursement');
                  setViewDetailsContent(offer.policies.bonus);
                  refViewDetailsPopup.current?.open();
                }
              }}
            />
          </Flex>
        )}
      </ScrollView>

      {status == 'active' ||
        (status == null && (
          <FloatingBottom>
            <Button
              useFullColor={true}
              value="Accept Offer & Proceed"
              onPress={() => {
                if (offer.employment == 'w2_loan_out') {
                  refEmploymentTypePopup.current?.open();
                  return;
                }

                acceptOffer();
              }}
            />
            <SecondaryButton
              value="Decline Offer"
              className="mt-2"
              onPress={() => refDeclineOffer.current?.open()}
            />
          </FloatingBottom>
        ))}

      <RBSheet
        ref={refDeclineOffer}
        animationType={'fade'}
        height={600}
        customStyles={{
          container: {
            borderTopLeftRadius: 28,
            borderTopRightRadius: 28,
            width:
              getGlobal().deviceType == DeviceType.DESKTOP ? '500px' : '100%',
            marginHorizontal:
              getGlobal().deviceType == DeviceType.DESKTOP ? 'auto' : 0,
          },
        }}
      >
        <Flex className="py-8 h-full" verticalAlign="between">
          <Flex align="start">
            <Flex className="w-full px-8">
              <Text type="sm-semibold" className="mb-2" color="coral-100">
                Are you sure you want to decline?
              </Text>
              <Text type="xs-medium" className="mb-2" color="denim-50">
                You can opt to decline the current offer but let the company
                know you’re willing to negotiate - this does not guarantee that
                you will be re-offered the role.
              </Text>
            </Flex>

            <Flex className="w-full gap-y-2 divide-y divide-denim-20 pt-8">
              <TouchableOpacity
                onPress={() => setDeclineType(0)}
                className={
                  declineType == 0
                    ? 'bg-coral-100/10 py-2.5 border-b border-dashed border-denim-30'
                    : 'bg-transparent py-2.5 border-b border-dashed border-transparent'
                }
              >
                <FlexRow className="py-2 px-8">
                  {declineType == 0 && (
                    <CheckRounded
                      width={16}
                      height={16}
                      fill={transformTailwindToHex('coral-100')}
                    />
                  )}
                  {declineType == 1 && (
                    <View className="h-4 w-4 border border-denim-50 rounded-full" />
                  )}

                  <FlexRow align="between" className="ml-2">
                    <Text
                      type="sm-semibold"
                      color={declineType == 0 ? 'coral-100' : 'denim-50'}
                    >
                      Decline Offer
                    </Text>
                    <Text
                      type="sm-semibold"
                      color={declineType == 0 ? 'coral-100' : 'denim-50'}
                    >
                      (default)
                    </Text>
                  </FlexRow>
                </FlexRow>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => setDeclineType(1)}
                className={
                  declineType == 1
                    ? 'bg-coral-100/10 py-2.5 border-b border-dashed border-denim-30'
                    : 'bg-transparent py-2.5 border-b border-dashed border-transparent'
                }
              >
                <FlexRow className="px-8 py-3">
                  {declineType == 1 && (
                    <Flex>
                      <CheckRounded
                        width={16}
                        height={16}
                        fill={transformTailwindToHex('coral-100')}
                      />
                    </Flex>
                  )}
                  {declineType == 0 && (
                    <View className="h-4 w-4 border border-denim-50 rounded-full" />
                  )}

                  <Flex className="ml-2">
                    <Text
                      type="sm-semibold"
                      color={declineType == 1 ? 'coral-100' : 'denim-50'}
                    >
                      Decline but Willing to Negotiate
                    </Text>
                  </Flex>
                </FlexRow>
              </TouchableOpacity>
            </Flex>
          </Flex>
          <Flex className="gap-y-2 px-8">
            <Button
              value="Confirm Selection & Decline Offer"
              onPress={() => rejectOffer()}
              color="coral-100"
              useFullColor={true}
              loading={loading}
            />
            <SecondaryButton
              value="Back"
              className="mt-2"
              onPress={() => refDeclineOffer.current?.close()}
            />
          </Flex>
        </Flex>
      </RBSheet>

      <RBSheet
        ref={refViewDetailsPopup}
        animationType={'fade'}
        height={600}
        customStyles={{
          container: {
            borderTopLeftRadius: 28,
            borderTopRightRadius: 28,
            width:
              getGlobal().deviceType == DeviceType.DESKTOP ? '500px' : '100%',
            marginHorizontal:
              getGlobal().deviceType == DeviceType.DESKTOP ? 'auto' : 0,
          },
        }}
      >
        <Flex className="p-8 h-full gap-y-2" verticalAlign="between">
          <Flex align="start">
            <Text type="sm-semibold" color="navy-light-100" className="mb-2">
              {viewDetailsTitle}
            </Text>
            <Text type="xs-medium" color="denim-100/60">
              {viewDetailsContent}
            </Text>
          </Flex>
          <SecondaryButton
            value="Close"
            onPress={() => refViewDetailsPopup.current.close()}
          />
        </Flex>
      </RBSheet>

      {/* NOT USED CURRENTLY */}
      <RBSheet
        ref={refEmploymentTypePopup}
        animationType={'fade'}
        height={600}
        customStyles={{
          container: {
            borderTopLeftRadius: 28,
            borderTopRightRadius: 28,
            width:
              getGlobal().deviceType == DeviceType.DESKTOP ? '500px' : '100%',
            marginHorizontal:
              getGlobal().deviceType == DeviceType.DESKTOP ? 'auto' : 0,
          },
        }}
      >
        <Flex className="p-8 h-full" verticalAlign="between">
          <Flex className="gap-y-8" align="start">
            <Flex>
              <Text type="sm-semibold" className="mb-2" color="navy-light-100">
                Confirm Employment Type
              </Text>
              <Text type="xs-medium" color="denim-100/60">
                This role is being offered as a{' '}
                {offer.employment == 'w2' ? 'W-2' : 'Contractor'}, however if
                you would prefer to work as a{' '}
                {offer.employment == 'w2' ? 'Loan-Out' : 'W-2'} instead please
                select it from the options below:
              </Text>
            </Flex>

            <Flex className="w-full space-y-2 divide-y divide-denim-20">
              <TouchableOpacity onPress={() => setAcceptType(0)}>
                <FlexRow className={`p-2 py-3 rounded-lg`}>
                  {acceptType == 0 && (
                    <CheckRounded
                      width={16}
                      height={16}
                      fill={transformTailwindToHex('navy-light-100')}
                    />
                  )}
                  {acceptType == 1 && (
                    <View className="h-4 w-4 border border-denim-50 rounded-full" />
                  )}

                  <Text
                    type="xs-semibold"
                    className="ml-2"
                    color={acceptType == 0 ? 'navy-light-100' : 'denim-50'}
                  >
                    Continue as{' '}
                    {offer.employment == 'w2' ? 'W-2 Crew' : 'Contractor'}{' '}
                    (default)
                  </Text>
                </FlexRow>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => setAcceptType(1)}>
                <FlexRow className={`p-2 py-3 rounded-lg`}>
                  {acceptType == 1 && (
                    <CheckRounded
                      width={16}
                      height={16}
                      fill={transformTailwindToHex('navy-light-100')}
                    />
                  )}
                  {acceptType == 0 && (
                    <View className="h-4 w-4 border border-denim-50 rounded-full" />
                  )}

                  <Text
                    type="xs-semibold"
                    className="ml-2"
                    color={acceptType == 1 ? 'navy-light-100' : 'denim-50'}
                  >
                    Continue as Loan-Out Crew
                  </Text>
                </FlexRow>
              </TouchableOpacity>
            </Flex>
          </Flex>
          <Flex className="gap-y-2">
            <SecondaryButton
              value="Back"
              onPress={() => refEmploymentTypePopup.current.close()}
            />
            <Button
              value="Confirm Selection & Continue"
              onPress={() => {
                refEmploymentTypePopup.current.close();
              }}
              color="navy-light-100"
              useFullColor={true}
              loading={loading}
              className="w-full"
            />
          </Flex>
        </Flex>
      </RBSheet>
    </Flex>
  );
}
