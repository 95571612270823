import React, { useState } from 'react';

import Text from '@components/General/Text';
import Flex from '@components/Views/Flex';
import { Image, SafeAreaView, TouchableOpacity } from 'react-native';
import { StackScreenProps } from '@react-navigation/stack';
import { NonAuthStackParamList } from '@src/routes';
import Button from '@components/Buttons/Button';
import Header from '@components/Views/Header';

import FlexRow from '@components/Views/FlexRow';

import Book2 from '@assets/icons/book2.svg';
import Calendar from '@assets/icons/calendar.svg';
import Film from '@assets/icons/film.svg';
import Invoice from '@assets/icons/invoice.svg';

import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { transformTailwindToHex } from '@src/helpers';

type HowItWorksProps = StackScreenProps<NonAuthStackParamList, 'HowItWorks'>;
export default function HowItWorks({ navigation }: HowItWorksProps) {
  const insets = useSafeAreaInsets();
  const [scrollIndex, setScrollIndex] = useState(0);

  return (
    <Flex className="flex-1 bg-white">
      <Header title="Welcome" showBack={false} />
      <SafeAreaView className="flex-1">
        <Flex verticalAlign="between" className="flex-grow mx-5 mt-12">
          <Flex>
            <Image
              source={require('@assets/images/logo-navy-light-100.png')}
              className="self-center h-10 w-full"
              resizeMode="contain"
            />

            <Flex className="mb-6 pt-6 mx-8">
              <Text
                type="lg-semibold"
                color="navy-light-100"
                className="mb-2 text-center"
              >
                This production is using FilmUp to staff, manage, & pay crew.
              </Text>
              <Text
                type="xs-semibold"
                color="coral-100"
                className="mb-12 text-center"
              >
                By accepting this offer your will be required to use the FilmUp
                App to submit your documents & timecards.
              </Text>

              <Text type="lg-semibold" color="navy-light-100" className="mb-2">
                How it Works
              </Text>

              {[
                {
                  icon: <Book2 width={20} height={20} fill="white" />,
                  blueText: 'Sign & Submit',
                  text: ' Documents',
                },
                {
                  icon: <Film width={20} height={20} fill="white" />,
                  blueText: 'Manage',
                  text: ' Productions & Schedules',
                },
                {
                  icon: <Calendar width={20} height={20} fill="white" />,
                  blueText: 'Submit',
                  text: ' Timecards & Expenses',
                },
                {
                  icon: <Invoice width={20} height={20} fill="white" />,
                  blueText: 'Track & Save',
                  text: ' Paystubs',
                },
              ].map((row) => (
                <FlexRow verticalAlign="center" align="start" className="mb-3">
                  <Flex
                    verticalAlign="center"
                    align="center"
                    className={`h-8 w-8 bg-navy-light-100 rounded-lg mr-3`}
                  >
                    {row.icon}
                  </Flex>
                  <FlexRow className="flex-grow">
                    <Text type="sm-semibold" color="navy-light-100">
                      {row.blueText}
                    </Text>
                    <Text type="sm-semibold" color="denim-100">
                      {row.text}
                    </Text>
                  </FlexRow>
                </FlexRow>
              ))}
            </Flex>
          </Flex>
          <Flex
            className="gap-y-5"
            style={{ paddingBottom: insets.bottom + 20 }}
          >
            <Button
              value="Let’s Do This"
              onPress={() => navigation.navigate('GettingStarted')}
              color="navy-light-100"
              useFullColor={true}
            />
          </Flex>
        </Flex>
      </SafeAreaView>
    </Flex>
  );
}
